import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding: 15px 0;
  border-style: solid;
  border-width: 1px 0 0;
  border-color: rgba(204, 210, 227, 1);
`;

export const Image = styled.img`
  height: 65px;
`;
export const Details = styled.div`
  padding-left: 20px;
  margin-top: auto;
  margin-bottom: auto;
`;

export const Title = styled.div`
  line-height: 24px;
  height: auto;
  text-align: left;
  font-family: 'Utopia Std', serif;
  font-size: 20px;
`;

export const Prices = styled.div`
  display: flex;
  flex-direction: row;
`;
export const Rrp = styled.div`
  text-decoration: line-through;
  font-family: 'Neuzeit Grotesk', sans-serif;
  color: #001c72;
`;
export const Dp = styled.div`
  font-family: 'Neuzeit Grotesk', sans-serif;
  color: #bc2e3e;
  padding-left: 5px;
`;
