import React, { useState, useContext, useEffect } from 'react';
import * as S from './LocalCartItems.styles';
import FunnelContext from '../../context/FunnelContext';
import LocalCartText from '../LocalCartText/LocalCartText';
import useLocalStorage from '../../hooks/useLocalStorage';

const LocalCartItems = () => {
  const { currency, localCartItems, setLocalCartItems } = useContext(
    FunnelContext
  );
  const [localCart] = useLocalStorage('local-cart');
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!loaded) {
      setLocalCartItems(localCart.items);
      setLoaded(true);
    }
  }, [loaded, setLoaded, localCartItems, setLocalCartItems, localCart]);

  const Value = () => (
    <>
      {localCartItems?.map((item, index) => {
        const {
          bump,
          display_title,
          image,
          perceived_rrp,
          discounted_price
        } = item;
        return (
          bump && (
            <S.Container key={index}>
              <S.Image src={image} />
              <S.Details>
                <S.Title>{display_title}</S.Title>
                <S.Prices>
                  <S.Rrp>
                    {currency?.symbol}
                    {perceived_rrp.toFixed(2)}
                  </S.Rrp>
                  <S.Dp>
                    {currency?.symbol}
                    {discounted_price.toFixed(2)}
                  </S.Dp>
                </S.Prices>
                1 Tin&nbsp;
                <LocalCartText displayValue="main-product-category" />
                <br />
                <LocalCartText displayValue="main-product-frequency" />
              </S.Details>
            </S.Container>
          )
        );
      })}
    </>
  );

  return <Value />;
};

export default LocalCartItems;
